<template>
  <div id="google_translate_element">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h3 class="text-left text-primary mb-0">Routers</h3>
    </div>
    <div class="separator-breadcrumb border-top"></div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ul class="nav nav-tabs mb-4 nav-justified">
              <li class="nav-item">
                <a
                  class="nav-link active text-uppercase"
                  id="home-icon-pill"
                  data-toggle="pill"
                  @click="setActiveTab('Manufacturer')"
                  role="tab"
                  aria-controls="homePIll"
                  aria-selected="true"
                >
                  <i class="nav-icon i-Settings-Window mr-1"></i>
                  <span class="font-weight-bold">Register Device</span>
                </a>
              </li>
            </ul>
            <DataTable
              :columns="columns"
              :rows="routers"
              :activeTab="activeTab"
              @display-edit-form="toggleEditForm"
              @display-qr-code="displayQrCode"
              @get-routers="getRouters"
            />
          </div>
        </div>
      </div>
    </div>

    <b-modal ref="edit-modal" hide-footer hide-backdrop>
      <RouterForm :router="activeRouter" @hide-edit-form="toggleEditForm" />
    </b-modal>
    <b-modal ref="router-qr" hide-footer>
      <QrCode :router="activeRouter" />
    </b-modal>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import RouterForm from "../../components/Router/RouterForm";
import QrCode from "../../components/Router/QrCode";
import axios from "axios";

export default {
  components: {
    DataTable,
    QrCode,
    RouterForm
  },
  data() {
    return {
      routers: [],
      error: "",
      activeRouter: {},
      activeTab: "Manufacturer"
    };
  },
  computed: {
    columns() {
      const columns = [
        { field: "serial_number", label: "Serial Number" },
        { field: "model_number", label: "model number" },
        { field: "ip_address", label: "ip Address" },
        { field: "imei", label: "imei" },
        { field: "os_version_id", label: "os version id" },
        { field: "created", label: "created", formatFn: this.formatDateTime },
        { field: "qr_code", label: "Qr Code", sortable: false }
      ];
      if (this.activeTab === "Manufacturer") {
        const actionField = {
          field: "action",
          label: "Action",
          sortable: false
        };
        columns.splice(7, 0, actionField);
      }
      return columns;
    }
  },
  mounted() {
    this.getRouters("Manufacturer");
  },
  methods: {
    setActiveTab(tab) {
      this.activeTab = tab.split(" ").join("+");
      this.getRouters(this.activeTab);
    },
    displayQrCode(router) {
      this.activeRouter = router;
      this.$refs["router-qr"].show();
    },
    hideNewRouterModal() {
      this.$refs["new-router"].hide();
    },
    toggleEditForm(router) {
      this.activeRouter = router;
      this.$refs["edit-modal"].toggle();
    },
    formatDateTime(value) {
      return this.$moment(value).format("dddd, MMMM Do YYYY, h:mm a");
    },
    getRouters(location, clearRouters) {
      if (clearRouters) {
        this.routers = [];
      }
      let routers_url = `v1/routers/`;
      if (location) {
        routers_url = `v1/routers/?location=${location}`;
      }

      axios
        .get(routers_url)
        .then(response => {
          if (response.status === 200) {
            this.routers = response.data.results;
          }
        })
        .catch(error => {
          if (error.response) {
            this.$swal("Error", `${error.response.data.detail}`, "error");
          }
        });
    }
  }
};
</script>
