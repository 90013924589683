<template>
  <div>
    <div class="row mb-3">
      <div :id="router.serial_number" class="col-md-12 text-center">
        <QrcodeVue
          :value="productQrData"
          :size="300"
          level="H"
          :renderAs="'svg'"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 text-center">
        <b-button class="outline-success" @click="print"
          >Print Qr code</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import QrcodeVue from "qrcode.vue";
export default {
  name: "QrCode",
  components: {
    QrcodeVue
  },
  props: {
    router: {
      type: Object,
      default() {
        return {
          nas_identifier: ""
        };
      }
    }
  },
  computed: {
    productQrData() {
      const data = {
        id: this.router.id,
        serial_number: this.router.serial_number,
        model_number:this.router.model_number,
        os_version_id:this.router.os_version_id,
        ip_address:this.router.ip_address,
        imei:this.router.imei,
        type: "router"
      };
      return JSON.stringify(data);
    }
  },
  methods: {
    print() {
      this.$htmlToPaper(this.router.serial_number);
    }
  }
};
</script>
